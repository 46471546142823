import React from "react"
import Jobs from "./Jobs"
import Backthree from "../common/back/Backthree"

const JobsPage = () => {
  return (
    <>
      <Backthree title='Jobs and Scholarships' />
      <Jobs />
    </>
  )
}

export default JobsPage
