import React from "react"
import Heading from "../common/heading/Heading"
import "./about.css"
import { homeAbout } from "../../dummydata"
import Awrapper from "./Awrapper"
import { Link } from "react-router-dom"

const AboutCard = () => {
  return (
    <>
      <section className='aboutHome'>
        <div className='container flexSB'>
          <div className='left row'>
            <img src='./images/abt-1.jpg' alt='' />
          </div>
          <div className='right row'>
            <Heading subtitle='LEARN ALL THE BASICS' title='Here are all the services we offer.' />
            <div className='items'>
              {homeAbout.map((val) => {
                return (
                  <Link to={val.page}>
                  <div className='item flexSB'>
                    <div className='img'>
                      <img src={val.cover} alt='' />
                    </div>
                    <div className='text'>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                  </Link>

                )
              })}
            </div>
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default AboutCard
